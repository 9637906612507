export const reviewsData = [
  
  {
    id: 1,
    imageSrc: require('../Images/reviews/reviews1.png'),
  },
  {
    id: 2,
    imageSrc: require('../Images/reviews/reviews2.jpg'),
  },
  {
    id: 3,
    imageSrc: require('../Images/reviews/reviews3.JPG'),
  },
  {
    id: 4,
    imageSrc: require('../Images/reviews/reviews4.JPG'),
  },
  {
    id: 5,
    imageSrc: require('../Images/reviews/reviews5.JPG'),
  },
  {
    id: 6,
    imageSrc: require('../Images/reviews/reviews6.JPG'),
  },
  {
    id: 7,
    imageSrc: require('../Images/reviews/reviews7.JPG'),
  },
  {
    id: 8,
    imageSrc: require('../Images/reviews/reviews8.JPG'),
  },
  {
    id: 9,
    imageSrc: require('../Images/reviews/reviews9.JPG'),
  },
  {
    id: 10,
    imageSrc: require('../Images/reviews/reviews10.JPG'),
  },
 
];
