import './ProductSize.css';

export const ProductSize = () => {
  return (
    <section id="productSize" className='productSize'>
      <h2>Розмірна сітка та заміри виробу</h2>
      <table className='productSize-table'>
  <thead>
    <tr>
      <th>Розмір</th>
      <th>Обхват грудей, см</th>
      <th>Довжина, см</th>
      <th>Рукав, см</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>42-46</td>
      <td>90-96</td>
      <td>45</td>
      <td>62</td>
    </tr>
    <tr>
    <td>48-50</td>
      <td>96-104</td>
      <td>46</td>
      <td>62</td>
    </tr>
    
  </tbody>
</table>

      {/* <p className='productSizeText'><span className='productSize-bolt'>*</span>   Розміри розраховані сидіти вільно а фасон відповідає сучасним трендам, підходе на всі типи фігур та має широку варіативність подачі образу та об'ємності силуету.</p> */}
      <img className='line' src={require('../../Images/line.png')} alt="line" />
    </section>
  );
};
