import './AboutProduct.css'

export const AboutProduct = () => {
  return (
    <section className='aboutProduct'>
      <ul className='aboutProduct-list'>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct1.jpg')}alt="Тканина" />
          <h4 className='aboutProduct-listTitle'>Шкіра</h4>
          <p className='aboutProduct-listText'>Високоякісна екошкіра, яка не тільки виглядає як натуральна шкіра, але також є екологічно чистою та етичною альтернативою. Що навідміну від натуральної, більш стійка до стирання та менш парка, завдяки сучасним технологіям виготовлення.</p>
        </li>
        <li className='aboutProduct-item'>
          <img className='about-img' src={require('../../Images/aboutProduct2.jpg')} alt="Рукава" />
          <h4 className='aboutProduct-listTitle'>Підкадка</h4>
          <p className='aboutProduct-listText'>Підкладка з саржі забезпечує комфортне та м'яке носіння, вона захищає Вас та нижній одяг від натирань та дає додаткового тепла, також завдяки ній виріб стає більш зносостійким та довше зберігає свій привабливий вигляд.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct3.jpg')} alt="Фасон" />
          <h4 className='aboutProduct-listTitle'>Фасон</h4>
          <p className='aboutProduct-listText'>Ремінець-зав'язка для акценту на талії допомагає створити стрункий силует на будь-яких параметрах, відповідає трендам та одночасно полегшує підбір розміру.</p>
        </li>
        <li className='aboutProduct-item '>
          <img className='about-img' src={require('../../Images/aboutProduct4.jpg')}alt=" Універсальність" />
          <h4 className='aboutProduct-listTitle'>Деталі</h4>
          <p className='aboutProduct-listText'>Продумані якісні деталі для ефектного та трендового вигляду: асиметрична блискавка, що є особливістю косух, пасок на талії що дозволяє регулювати посадку, практичні кишені ледве помітні аби зберегти стильний мінімалізм.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct5.jpg')} alt="Комірець" />
          <h4 className='aboutProduct-listTitle'>Комірець</h4>
          <p className='aboutProduct-listText'>Завдяки м'якому, але якісному матеріалу, він добре зберігає форму, але при цьому має більше свободи в лініях та може легко змінювати своє положення залежно від застібання блискавки за Вашим бажанням.</p>
        </li>
      
      </ul>
    </section>
  );
};
