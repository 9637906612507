export const productsData = [
 
  {
    id: 1,
    imageSrc: require('../Images/products/product1.jpeg'),
    color: 'Чорний',
    buttonColorName: 'Чорну',
    price: 2102,
    todayPrice: 1472,
    discount: 30,
    quantity:1,
    size: "0"
  },
  {
    id: 2,
    imageSrc: require('../Images/products/product2.jpeg'),
    color: 'Капучино',
    buttonColorName: 'Капучино',
    price: 2102,
    todayPrice: 1472,
    discount: 30,
    quantity:1,
    size: "0"
  },
  {
    id: 3,
    imageSrc: require('../Images/products/product3.jpeg'),
    color: 'Рожевий',
    buttonColorName: 'Рожеву',
    price: 2102,
    todayPrice: 1472,
    discount: 30,
    quantity:1,
    size:"0"
  },
  
];
